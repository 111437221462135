import SearchPage from '@/pages/Search/SearchPage'

//DASHBOARDS
import Dashboard from '@/layouts/DashboardLayout'
import ResellerDashboard from '@/pages/Dashboards/ResellerDashboard'
import StaffDashboard from '@/pages/Dashboards/StaffDashboard'
import ClientDashboard from '@/pages/Dashboards/ClientDashboard'
import SearchClientDashboard from '@/pages/Dashboards/Search/ClientDashboard'
import SearchClientDashboardForClient from '@/pages/Dashboards/Search/ClientDashboardForClient'

//ORDER
import OrdersList from '@/layouts/OrdersList'

//ORDER TRACKING
import OrderTrackingLayout from '@/layouts/OrderTrackingLayout'

//CLIENT
import ClientLayout from '@/layouts/ClientLayout'
import ClientSales from '@/pages/Clients/ClientSales'
import ClientDocuments from '@/pages/Clients/ClientDocuments'
import ClientStatement from '@/pages/Clients/ClientStatement'
import ClientDocumentation from '@/pages/Clients/ClientDocumentation'
import ClientProfile from '@/pages/Clients/ClientProfile'
import ClientPersons from '@/pages/Clients/ClientPersons'
import ClientHistory from '@/pages/Clients/ClientHistory'
import ClientSettlements from '@/pages/Clients/ClientSettlements'
import ClientSettleAccount from '@/pages/Clients/ClientSettleAccount'
import ClientReversals from '@/pages/Clients/ClientReversals'
import ClientWorkOrders from '@/pages/Clients/ClientWorkOrders'
import WorkOrderDetails from '@/pages/WorkOrders/WorkOrderDetails'
import ClientHelpdeskTickets from '@/pages/Clients/ClientHelpdeskTickets'
import ArchivedService from '@/pages/Services/Views/ArchivedService'
import ClientsList from '@/pages/Clients/ClientsList'

//RESELLER
import Resellers from '@/pages/Resellers/Resellers'
import ResellerClients from '@/pages/Resellers/ResellerClients'
import ResellerCommission from '@/pages/Resellers/ResellerCommission'

//WIZARDS
import AddClientWizard from '@/pages/Clients/AddClientWizard'

//TOOLS
import FeasibilityTool from '@/pages/Tools/FeasibilityTool'
import NotificationCentre from '@/pages/Tools/NotificationCentre'
import IpAllocationManagement from '@/pages/Tools/IpAllocationManagement'
import IpUsageLookup from '@/pages/Tools/IpUsageLookup'
import WhoisLookup from '@/pages/Tools/WhoisLookup'
import PersonDetail from '@/pages/Tools/PersonDetail' // Remember to remove this component once the pipedrive integration is fixed
import ReverseDnsZoneIpv4 from '@/pages/Tools/ReverseDns/Zones/ZonesIpv4'
import ReverseDnsZoneIpv6 from '@/pages/Tools/ReverseDns/Zones/ZonesIpv6'
import ReverseDnsRecordIpv4 from '@/pages/Tools/ReverseDns/Records/RecordsIpv4'
import ReverseDnsRecordIpv6 from '@/pages/Tools/ReverseDns/Records/RecordsIpv6'

//DATA CAPTURE
import MinutesBillingCapture from '@/pages/DataCapture/MinutesBillingCapture'
import LiquidSubscriptionImport from '@/pages/DataCapture/LiquidSubscriptionImport'


//ADMIN
import ProductList from '@/pages/Admin/ProductList'
import ProductDetail from '@/pages/Admin/ProductDetail'
import NewProduct from '@/pages/Admin/NewProduct'
import DocumentTemplates from '@/pages/Admin/DocumentTemplates'
import DomainContactManagement from '@/pages/Admin/DomainContactManagement'
import DnsViewManagement from '@/pages/Admin/DnsViewManagement'
import UserManagement from '@/pages/Admin/UserManagement'
import RealmManagement from '@/pages/Admin/RealmManagement'
import ImportClientSegment from '@/pages/Admin/ImportClientSegment'
import SaleTagsManagement from '@/pages/Admin/SaleTagsManagement'
import SaleTagsAssignment from '@/pages/Admin/SaleTagsAssignment'
import SystemSettingsManagement from '@/pages/Admin/SystemSettingsManagement'
import GoogleWorkspaceInvoiceCapture from '@/pages/Admin/GoogleWorkspaceInvoiceCapture'
import SynaqInvoiceCapture from '@/pages/Admin/SynaqInvoiceCapture'
import CabinetPowerBillingCapture from '@/pages/Admin/CabinetPowerBillingCapture'
import MimecastDataCapture from '@/pages/Admin/MimecastDataCapture'
import SystemNotifications from '@/pages/Admin/SystemNotifications'
import GlobalDomainPricing from '@/pages/Admin/GlobalDomainPricing'
import AwsBillingCapture from '@/pages/Admin/AwsBilling/AwsBillingCapture'
import IonlineUsageUpload from '@/pages/Admin/iOnlineUsage/IonlineUsageUpload'
import ClassificationManagement from '@/pages/Admin/ClassificationManagement'
import NewOrder from '@/pages/Admin/NewOrder'

//TASKS
import TaskList from '@/pages/Tasks/TaskList'
import RunNewTask from '@/pages/Tasks/RunNewTask'
import SendStatements from '@/pages/Tasks/Accounts/SendStatements'
import ProcessInvoices from '@/pages/Tasks/Accounts/ProcessInvoices'
import BankStatementUpload from '@/pages/Tasks/Accounts/BankStatementUpload'
import BankStatements from '@/pages/Tasks/Accounts/BankStatements'

//SALES
import SaleLayout from '@/layouts/SaleLayout'
import SaleDocumentation from '@/pages/Sales/SaleDocumentation'
import SaleAdminDetail from '@/pages/Sales/SaleAdminDetail'
import SaleBillingDetail from '@/pages/Sales/SaleBillingDetail'
import SaleBillingHistory from '@/pages/Sales/SaleBillingHistory'
import SaleProductDetails from '@/pages/Sales/SaleProductDetails'
import SaleHistory from '@/pages/Sales/SaleHistory'
import ObjHistory from '@/pages/Sales/ObjHistory'

//SERVICES
import LteService from '@/pages/Services/Views/Lte/LteService'
import FtthService from '@/pages/Services/Views/Ftth/FtthService'
import FttbService from '@/pages/Services/Views/Fttb/FttbService'
import FttoService from '@/pages/Services/Views/Ftto/FttoService'
import PppoeFttbService from '@/pages/Services/Views/Fttb/PppoeFttbService'
import ParkFibreService from '@/pages/Services/Views/ParkFibre/ParkFibreService'
import Layer2Service from '@/pages/Services/Views/Layer2/Layer2Service'
import OpenserveService from '@/pages/Services/Views/Openserve/OpenserveService'
import OpenserveMplsVpnMultiConnectivity from '@/pages/Services/Views/Mpls/OpenserveMplsVpnMultiConnectivity'
import AdslLineService from '@/pages/Services/Views/Openserve/AdslLineOnlyService'
import L2tpStaticIpService from '@/pages/Services/Views/L2tpStaticIp/L2tpStaticIpService'
import Layer2LinkService from '@/pages/Services/Views/Layer2Link/Layer2LinkService'
import ManagedApnService from '@/pages/Services/Views/ManagedApn/ManagedApnService'
import PrivateMtnApnService from '@/pages/Services/Views/ManagedApn/PrivateMtnApnService'
import DomainService from '@/pages/Services/Views/Domains/DomainService'
import DomainHostingOnlyService from '@/pages/Services/Views/Domains/DomainHostingOnlyService'
import ManagedServicesNCentral from '@/pages/Services/Views/ManagedServices/ManagedServicesNCentral'
import CPanelHosting from '@/pages/Services/Views/Domains/CPanelHosting'
import AxxessLteService from '@/pages/Services/Views/Lte/AxxessLteService'
import AxxessFtthService from '@/pages/Services/Views/Ftth/Axxess/AxxessFtthService'
import ZimbraMailService from '@/pages/Services/Views/Mail/ZimbraMailService'
import MplsVpn from '@/pages/Services/Views/Mpls/MplsVpn'
import MplsOverInternet from '@/pages/Services/Views/Mpls/MplsOverInternet'
import RouterRentalService from '@/pages/Services/Views/Rental/RouterRentalService'
import EquipmentRentalService from '@/pages/Services/Views/Rental/EquipmentRentalService'
import CabinetRentalService from '@/pages/Services/Views/DataCentre/CabinetRentalService'
import MimecastMailService from '@/pages/Services/Views/Mimecast/MimecastMailService'
import CrossConnectService from '@/pages/Services/Views/DataCentre/CrossConnectService'
import CabinetPowerBillingService from '@/pages/Services/Views/DataCentre/CabinetPowerBillingService'
import DiginetService from '@/pages/Services/Views/Diginet/DiginetService'
import MetroConnectMpls from '@/pages/Services/Views/Mpls/MetroConnectMpls'
import OpenserveMplsOverFibreService from '@/pages/Services/Views/Mpls/OpenserveMplsOverFibreService'
import SolarwindsBackupService from '@/pages/Services/Views/Solarwinds/SolarwindsBackupService'
// import HostedLanService from '@/pages/Services/Views/Lan/HostedLanService'
import HostedVlanService from '@/pages/Services/Views/Vlan/HostedVlanService'
import ActiveEthernetService from '@/pages/Services/Views/Vumatel/ActiveEthernetService'
import ZimbraSharedMailService from '@/pages/Services/Views/Mail/ZimbraSharedMailService'
import ZimbraSharedDomain from '@/pages/Services/Views/Mail/ZimbraSharedDomain'
import GoogleWorkspaceService from '@/pages/Services/Views/Google/GoogleWorkspaceService'
import LiquidLayer2Service from '@/pages/Services/Views/Liquid/LiquidLayer2Service'
import HomeVoiceService from '@/pages/Services/Views/Voip/HomeVoiceService'
import WanatelPbxService from '@/pages/Services/Views/Voip/WanatelPbxService'
import BulkDidService from '@/pages/Services/Views/Voip/BulkDidService'
import InternationalDidService from '@/pages/Services/Views/Voip/InternationalDidService'
import Cx3PbxService from '@/pages/Services/Views/Voip/Cx3PbxService'
import VoiceMinutesService from '@/pages/Services/Views/Voip/VoiceMinutesService'
import LiquidSubscriptionService from '@/pages/Services/Views/Liquid/LiquidSubscriptionService'
import DataCentreInternetService from '@/pages/Services/Views/DataCentre/DataCentreInternetService'
import Layer3Service from '@/pages/Services/Views/Layer3/Layer3Service'
import VirtualServerService from '@/pages/Services/Views/Virtual/VirtualServerService'
import SplaService from '@/pages/Services/Views/Spla/SplaService'
import SoftwareService from '@/pages/Services/Views/Software/SoftwareService'
import Office365BackupService from '@/pages/Services/Views/Office/Office365BackupService'
import SslCertificateService from '@/pages/Services/Views/SslCertificate/SslCertificateService'
import Office365Service from '@/pages/Services/Views/Office/Office365Service'
import CyberRiskAwareService from '@/pages/Services/Views/CyberRiskAware/CyberRiskAwareService'
import AuthSmtpService from '@/pages/Services/Views/Smtp/AuthSmtpService'
import SynaqService from '@/pages/Services/Views/Synaq/SynaqService'
import InternalDnsService from '@/pages/Services/Views/Domains/InternalDnsService'
import WebsiteStarterService from '@/pages/Services/Views/WebHosting/WebsiteStarterService'
import WebsitePhpService from '@/pages/Services/Views/WebHosting/WebsitePhpService'
import VirtualServerSsdCloudService from '@/pages/Services/Views/Virtual/VirtualServerSsdCloudService'
import VirtualServerEnterpriseService from '@/pages/Services/Views/Virtual/VirtualServerEnterpriseService'
import AwsService from '@/pages/Services/Views/Aws/AwsService'
import IonlineApnService from '@/pages/Services/Views/ManagedApn/IonlineApnService'
import DeviceManagementService from '@/pages/Services/Views/Device/DeviceManagementService'
import SlaService from '@/pages/Services/Views/ServiceLevelAgreement/SlaService'

// VIEW ALL SERVICES

import ViewServices from '@/pages/Services/ViewServices';

// STAFF PORTAL REDIRECT

import StaffPortalServiceRedirect from '@/components/Services/StaffPortalServiceRedirect'

//LEGACY SERVICES
import FaxToEmailService from '@/pages/Services/Views/FaxToEmail/FaxToEmailService'
import LegacyDomainRegistrationService from '@/pages/Services/Views/Legacy/LegacyDomainRegistrationService'
import LegacyRemoteDomainService from '@/pages/Services/Views/Legacy/LegacyRemoteDomainService'
import LegacySslCertificateService from '@/pages/Services/Views/Legacy/LegacySslCertificateService'
import LegacyGenericService from '@/pages/Services/Views/Legacy/LegacyGenericService'
import LegacyDeviceManagementService from '@/pages/Services/Views/Legacy/LegacyDeviceManagementService'
import LegacyParkFibreService from '@/pages/Services/Views/Legacy/LegacyParkFibreService'
import LegacyHostedVlanNetworkService from '@/pages/Services/Views/Legacy/LegacyHostedVlanNetworkService'
import LegacyWebHostingService from '@/pages/Services/Views/Legacy/LegacyWebHostingService'
import LegacyServerHostingService from '@/pages/Services/Views/Legacy/LegacyServerHostingService'
import LegacyServerHostingFirewallService from '@/pages/Services/Views/Legacy/LegacyServerHostingFirewallService'
import LegacyAuthSmtpService from '@/pages/Services/Views/Legacy/LegacyAuthSmtpService'

//REGRADES
import LteRegrades from '@/pages/Services/Views/Lte/LteRegrades'
import FtthRegrades from '@/pages/Services/Views/Ftth/FtthRegrades'
import OpenserveServiceRegrades from '@/pages/Services/Views/Openserve/OpenserveServiceRegrades'
import FttbRegrades from '@/pages/Services/Views/Fttb/FttbRegrades';
import WebHostingRegrade from '@/pages/Services/Views/WebHosting/WebHostingRegrade'

//DOCUMENTS
import DocumentEdit from '@/pages/Documents/DocumentDetail'

//REPORTING
import Reporting from '@/pages/Reporting/Reporting'

//Tracking
import DomainExpiryManagement from '@/pages/Tracking/DomainExpiryManagement'
import ManagedDevicesManagement from '@/pages/Tracking/ManagedDevicesManagement'

export default [{
    path: 'search',
    name: 'search',
    component: SearchPage,
    meta: {
        title: 'Search Results',
        breadcrumb: 'Search Results',
        permissions: ['*']
    }
},
{
    path: 'dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
        title: 'Dashboard',
        breadcrumb: 'Dashboard',
        permissions: ['STAFF', 'RESELLER', 'CLIENT']
    },
    children: [{
        path: 'reseller',
        name: 'resellerdashboard',
        component: ResellerDashboard,
        meta: {
            title: 'Reseller Dashboard',
            breadcrumb: 'Reseller Dashboard',
            context: 'dashboard',
            permissions: ['RESELLER']
        }
    },
    {
        path: 'staff',
        name: 'staffdashboard',
        component: StaffDashboard,
        meta: {
            title: 'Staff Dashboard',
            breadcrumb: 'Staff Dashboard',
            context: 'dashboard',
            permissions: ['STAFF']
        }
    },
    {
        path: 'client',
        name: 'clientdashboard',
        component: ClientDashboard,
        meta: {
            title: 'Client Dashboard',
            breadcrumb: 'Client Dashboard',
            context: 'dashboard',
            permissions: ['CLIENT']
        }
    }
    ],
},
{
    path: 'orderslist',
    name: 'orderslist',
    component: OrdersList,
    meta: {
        title: 'Orders',
        breadcrumb: 'Orders List',
        permissions: ['STAFF', 'MANAGE_ORDERS']
    }
},
{
    path: 'ordertracking',
    name: 'ordertracking',
    component: OrderTrackingLayout,
    meta: {
        title: 'Order Tracking',
        breadcrumb: 'Order Tracking',
        permissions: ['STAFF']
    }
},
{
    path: 'domainexpirymanagement',
    name: 'domainexpirymanagement',
    component: DomainExpiryManagement,
    meta: {
        title: 'Domain Expiry Management',
        breadcrumb: 'Domain Expiry Management',
        permissions: ['STAFF']
    }
},
{
    path: 'manageddevicesmanagement',
    name: 'manageddevicesmanagement',
    component: ManagedDevicesManagement,
    meta: {
        title: 'Managed Devices Management',
        breadcrumb: 'Managed Devices Management',
        permissions: ['STAFF']
    }
},
{
    path: 'addclient',
    name: 'addclient',
    component: AddClientWizard,
    meta: {
        title: 'Add Client',
        breadcrumb: 'Add Client',
        context: 'client',
        permissions: ['STAFF', 'RESELLER']
    },
},
{
    path: 'allclients',
    name: 'allclients',
    component: ClientsList,
    meta: {
        title: 'All Clients',
        breadcrumb: 'Client List',
        context: 'client',
        permissions: ['STAFF', 'RESELLER']
    },
},
{
    path: 'resellerslist',
    name: 'resellerslist',
    component: Resellers,
    meta: {
        title: 'List of Resellers',
        breadcrumb: 'List of Resellers',
        permissions: ['STAFF']
    }
},
{
    path: 'feasibilitytool',
    name: 'feasibilitytool',
    component: FeasibilityTool,
    meta: {
        title: 'Feasibility Tool',
        breadcrumb: 'Feasibility Tool',
        permissions: ['STAFF']
    }
},
{
    path: 'reporting',
    name: 'reporting',
    component: Reporting,
    meta: {
        title: 'Reporting',
        permissions: ['STAFF']
    }
},
{
    path: 'notificationcentre',
    name: 'notificationcentre',
    component: NotificationCentre,
    meta: {
        title: 'Notification Centre',
        breadcrumb: 'Notification Centre',
        permissions: ['STAFF']
    }
},
{
    path: 'ipallocationmanagement',
    name: 'ipallocationmanagement',
    component: IpAllocationManagement,
    meta: {
        title: 'IP Allocation Management',
        breadcrumb: 'IP Allocation Management',
        permissions: ['STAFF']
    }
},
{
    path: 'ipusagelookup',
    name: 'ipusagelookup',
    component: IpUsageLookup,
    meta: {
        title: 'IP Usage Lookup',
        breadcrumb: 'IP Usage Lookup',
        permissions: ['STAFF']
    }
},
{
    path: 'reversednszoneipv4',
    name: 'reversednszoneipv4',
    component: ReverseDnsZoneIpv4,
    meta: {
        title: 'Reverse DNS Management',
        breadcrumb: 'Reverse DNS Management',
        permissions: ['STAFF']
    },
},
{
    path: 'reversednszoneipv6',
    name: 'reversednszoneipv6',
    component: ReverseDnsZoneIpv6,
    meta: {
        title: 'Reverse DNS Management',
        breadcrumb: 'Reverse DNS Management',
        permissions: ['STAFF']
    },
},
{
    path: 'reversednsrecordipv4',
    name: 'reversednsrecordipv4',
    component: ReverseDnsRecordIpv4,
    meta: {
        title: 'Reverse DNS Management',
        breadcrumb: 'Reverse DNS Management',
        permissions: ['STAFF']
    },
},
{
    path: 'reversednsrecordipv6',
    name: 'reversednsrecordipv6',
    component: ReverseDnsRecordIpv6,
    meta: {
        title: 'Reverse DNS Management',
        breadcrumb: 'Reverse DNS Management',
        permissions: ['STAFF']
    },
},
{
    path: 'whoislookup',
    name: 'whoislookup',
    component: WhoisLookup,
    meta: {
        title: 'Whois Lookup',
        breadcrumb: 'Whois Lookup',
        permissions: ['STAFF']
    }
},

// Remember to remove this route once the pipedrive integration is fixed
{
    path: 'persondetail/:personNumber',
    name: 'persondetail',
    component: PersonDetail,
    meta: {
        title: 'Person detail',
        breadcrumb: 'Person detail',
        permissions: ['STAFF']
    }
},
{
    path: 'liquidsubscriptionimport',
    name: 'liquidsubscriptionimport',
    component: LiquidSubscriptionImport,
    meta: {
        title: 'Liquid Subscription Import',
        breadcrumb: 'Liquid Subscription Import',
        permissions: ['STAFF']
    }
},
{
    path: 'importsegments',
    name: 'importclientsegments',
    component: ImportClientSegment,
    meta: {
        title: 'Import Client Segments',
        breadcrumb: 'Import Client Segments',
        permissions: ['STAFF']
    }
},
{
    path: 'saletagsassignment',
    name: 'saletagsassignment',
    component: SaleTagsAssignment,
    meta: {
        title: 'Sale Tags Assignment',
        breadcrumb: 'Sale Tags Assignment',
        permissions: ['STAFF']
    }
},
{
    path: 'systemsettings',
    name: 'systemsettings',
    component: SystemSettingsManagement,
    meta: {
        title: 'System Settings Management',
        breadcrumb: 'System Settings Management',
        permissions: ['ADMIN']
    }
},
{
    path: 'googleinvoicecapture',
    name: 'googleinvoicecapture',
    component: GoogleWorkspaceInvoiceCapture,
    meta: {
        title: 'Google Workspace Invoice Capture',
        breadcrumb: 'Google Workspace Invoice Capture',
        permissions: ['STAFF']
    }
},
{
    path: 'synaqinvoicecapture',
    name: 'synaqinvoicecapture',
    component: SynaqInvoiceCapture,
    meta: {
        title: 'Synaq Invoice Capture',
        breadcrumb: 'Synaq Invoice Capture',
        permissions: ['STAFF']
    }
},
{
    path: 'powerbillingcapture',
    name: 'powerbillingcapture',
    component: CabinetPowerBillingCapture,
    meta: {
        title: 'Cabinet Power Billing Capture',
        breadcrumb: 'Cabinet Power Billing Capture',
        permissions: ['STAFF']
    }
},
{
    path: 'mimecastcapture',
    name: 'mimecastcapture',
    component: MimecastDataCapture,
    meta: {
        title: 'Mimecast Data Capture',
        breadcrumb: 'Mimecast Data Capture',
        permissions: ['STAFF']
    }
},
{
    path: 'minutesbillingcapture',
    name: 'minutesbillingcapture',
    component: MinutesBillingCapture,
    meta: {
        title: 'Minutes Billing Capture',
        breadcrumb: 'Minutes Billing Capture',
        permissions: ['STAFF']
    }
},
{
    path: 'awsbillingcapture',
    name: 'awsbillingcapture',
    component: AwsBillingCapture,
    meta: {
        title: 'AWS Billing Capture',
        breadcrumb: 'AWS Billing Capture',
        permissions: ['STAFF']
    }
},
{
    path: 'ionlineusageupload',
    name: 'ionlineusageupload',
    component: IonlineUsageUpload,
    meta: {
        title: 'iOnline Usage Upload',
        breadcrumb: 'iOnline Usage Upload',
        permissions: ['STAFF']
    }
},
{
    path: 'products',
    name: 'productlist',
    component: ProductList,
    meta: {
        title: 'Products',
        breadcrumb: 'Product List',
        context: 'products',
        permissions: ['STAFF']
    }
},
{
    path: 'products/detail/:productNumber',
    name: 'productdetail',
    component: ProductDetail,
    meta: {
        title: 'Product',
        breadcrumb: 'Product Detail',
        context: 'products',
        permissions: ['STAFF']
    }
},
{
    path: 'products/new/:productNumber',
    name: 'newproduct',
    component: NewProduct,
    meta: {
        title: 'Product',
        breadcrumb: 'New Ptroduct',
        context: 'products',
        permissions: ['STAFF']
    }
},
{
    path: 'tasks',
    name: 'tasklist',
    component: TaskList,
    meta: {
        title: 'Tasks',
        breadcrumb: 'Task List',
        context: 'tasks',
        permissions: ['STAFF']
    }
},
{
    path: 'runtask',
    name: 'runtask',
    component: RunNewTask,
    meta: {
        title: 'Run Task',
        breadcrumb: 'Run Task',
        context: 'tasks',
        permissions: ['STAFF']
    }
},
{
    path: 'tasks/sendstatements',
    name: 'sendstatements',
    component: SendStatements,
    meta: {
        title: 'Send Statements',
        breadcrumb: 'Send Statements',
        context: 'home',
        permissions: ["ADMIN", "ACCOUNTS_ADMIN", "ACCOUNTS_STAFF"]
    }
},
{
    path: 'tasks/processinvoices',
    name: 'processinvoices',
    component: ProcessInvoices,
    meta: {
        title: 'Process Invoices',
        breadcrumb: 'Process Invoices',
        context: 'home',
        permissions: ["ADMIN", "ACCOUNTS_ADMIN", "ACCOUNTS_STAFF"]
    }
},
{
    path: 'tasks/bankstatementupload',
    name: 'bankstatementupload',
    component: BankStatementUpload,
    meta: {
        title: 'Bank Statement Upload',
        breadcrumb: 'Bank Statement Upload',
        context: 'home',
        permissions: ["ADMIN", "ACCOUNTS_ADMIN", "BANK_STATEMENT", "ACCOUNTS_STAFF"]
    }
},
{
    path: 'tasks/bankstatements',
    name: 'bankstatements',
    component: BankStatements,
    meta: {
        title: 'Bank Statements',
        breadcrumb: 'Bank Statements',
        context: 'home',
        permissions: ["ADMIN", "ACCOUNTS_ADMIN", "BANK_STATEMENT", "ACCOUNTS_STAFF"]
    }
},
{
    path: 'doctemplates',
    name: 'doctemplates',
    component: DocumentTemplates,
    meta: {
        title: 'Document Templates',
        breadcrumb: 'Document Templates',
        permissions: ['STAFF']
    }
},
{
    path: 'domaincontactmanagement',
    name: 'domaincontactmanagement',
    component: DomainContactManagement,
    meta: {
        title: 'Domain Contact Management',
        breadcrumb: 'Domain Contact Management',
        permissions: ['STAFF']
    }
},
{
    path: 'dnsviewmanagement',
    name: 'dnsviewmanagement',
    component: DnsViewManagement,
    meta: {
        title: 'DNS View Management',
        breadcrumb: 'DNS View Management',
        permissions: ['STAFF']
    }
},
{
    path: 'classificationmanagement',
    name: 'classificationmanagement',
    component: ClassificationManagement,
    meta: {
        title: 'Classification Management',
        breadcrumb: 'Classification Management',
        permissions: ['STAFF']
    }
},
{
    path: 'neworder',
    name: 'neworder',
    component: NewOrder,
    meta: {
        title: 'New Order',
        breadcrumb: 'New Order',
        permissions:  ['STAFF', 'MANAGE_ORDERS']
    }
},
{
    path: 'globaldomainpricing',
    name: 'globaldomainpricing',
    component: GlobalDomainPricing,
    meta: {
        title: 'Global Domain Pricing',
        breadcrumb: 'Global Domain Pricing',
        permissions: ['STAFF']
    }
},
{
    path: 'usermanagement',
    name: 'usermanagement',
    component: UserManagement,
    meta: {
        title: 'User Management',
        breadcrumb: 'User Management',
        permissions: ['ADMIN']
    }
},
{
    path: 'realmmanagement',
    name: 'realmmanagement',
    component: RealmManagement,
    meta: {
        title: 'Realm Management',
        breadcrumb: 'Realm Management',
        permissions: ['STAFF']
    }
},
{
    path: 'notifications',
    name: 'notifications',
    component: SystemNotifications,
    meta: {
        title: 'Notifications',
        breadcrumb: 'Notifications',
        permissions: ['STAFF']
    }
},
{
    path: 'client/:clientNumber',
    name: 'client',
    redirect: 'client/:clientNumber/profile',
    component: ClientLayout,
    meta: {
        title: 'Client',
        breadcrumb: 'Client',
        permissions: ['*']
    },
    children: [{
        path: 'dashboard',
        name: 'searchclient',
        component: SearchClientDashboard,
        meta: {
            title: 'Client Dashboard',
            breadcrumb: 'Client Dashboard',
            context: 'dashboard',
            permissions: ['STAFF']
        }
    },
    {
        path: 'clientsdashboard',
        name: 'searchclientforclient',
        component: SearchClientDashboardForClient,
        meta: {
            title: 'Client Dashboard',
            breadcrumb: 'Client Dashboard',
            context: 'dashboard',
            permissions: ['CLIENT']
        }
    },
    {
        path: 'sales/all',
        name: 'clientsales',
        component: ClientSales,
        meta: {
            title: 'Client',
            breadcrumb: 'Client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'services',
        name: 'allservices',
        component: ViewServices,
        meta: {
            title: 'Client',
            breadcrumb: 'Client',
            permissions: ['SERVICES', 'CLIENT_ADMIN', 'CLIENT', 'BILLING', 'WORK_ORDERS']
        }
    },
    {
        path: 'clientorderslist',
        name: 'clientorderslist',
        component: OrdersList,
        meta: {
            title: 'Orders',
            breadcrumb: 'Orders',
            permissions: ['STAFF', 'MANAGE_ORDERS']
        }
    },
    {
        path: 'clientneworder',
        name: 'clientneworder',
        component: NewOrder,
        meta: {
            title: 'New Order',
            breadcrumb: 'New Order',
            permissions:  ['STAFF', 'MANAGE_ORDERS']
        }
    },
    // BEGIN Segment nav items for Client Menu

    {
        path: 'sales/adhoc',
        name: 'clientsalesadhoc',
        component: ClientSales,
        meta: {
            title: 'Ad Hoc',
            breadcrumb: 'Ad Hoc',
            permissions: ['SERVICES', 'CLIENT_ADMIN', 'CLIENT', 'BILLING', 'WORK_ORDERS']
        }
    },

    // END Segment nav items for Client Menu


    {
        path: 'sales/:saleNumber',
        name: 'clientsale',
        component: SaleLayout,
        meta: {
            title: 'Sale',
            breadcrumb: 'Sale',
            permissions: ['*']
        },
        children: [
            {
                path: 'redirect',
                name: 'staffportalserviceredirect',
                component: StaffPortalServiceRedirect,
                meta: {
                    title: 'Service Redirect',
                    breadcrumb: 'Service Redirect',
                    permissions: ['*']
                }
            },
            {
            path: 'lte',
            name: 'lteservice',
            component: LteService,
            meta: {
                title: 'LTE',
                breadcrumb: 'LTE',
                context: 'sale',
                permissions: ['*']
            },
            children: [

            ]
        },
        {
            path: 'lteregrades',
            name: 'lteregrade',
            component: LteRegrades,
            meta: {
                title: 'LTE Regrade',
                breadcrumb: 'Regrade',
                permissions: ['STAFF']
            },
        },
        {
            path: 'archivedservice',
            name: 'archivedservice',
            component: ArchivedService,
            meta: {
                title: 'Archived Service',
                breadcrumb: 'Archived',
                context: 'client',
                permissions: ['STAFF']
            }
        },
        {
            path: 'ftth',
            name: 'ftthservice',
            component: FtthService,
            meta: {
                title: 'FTTH',
                breadcrumb: 'FTTH',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'devicemanagement',
            name: 'devicemanagementservice',
            component: DeviceManagementService,
            meta: {
                title: 'Device Management',
                breadcrumb: 'Device Management',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'slaservice',
            name: 'slaservice',
            component: SlaService,
            meta: {
                title: 'Support SLA',
                breadcrumb: 'Support SLA',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'layer2',
            name: 'layer2service',
            component: Layer2Service,
            meta: {
                title: 'Layer 2',
                breadcrumb: 'Layer 2',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'ftthregrades',
            name: 'ftthregrade',
            component: FtthRegrades,
            meta: {
                title: 'FTTB Regrade',
                breadcrumb: 'Regrade',
                permissions: ['STAFF']
            },
        },
        {
            path: 'fttbregrades',
            name: 'fttbregrade',
            component: FttbRegrades,
            meta: {
                title: 'FTTH Regrade',
                breadcrumb: 'Regrade',
                permissions: ['STAFF']
            },
        },
        {
            path: 'linkafricafttb',
            name: 'linkafricafttb',
            component: PppoeFttbService,
            meta: {
                title: 'Link Africa Fttb',
                breadcrumb: 'Link Africa FTTB',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'dfabroadbandfibre',
            name: 'dfabroadbandfibre',
            component: PppoeFttbService,
            meta: {
                title: 'DFA Broadband Fibre',
                breadcrumb: 'DFA Broadband Fibre',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'parkfibrefttbservice',
            name: 'parkfibrefttbservice',
            component: ParkFibreService,
            meta: {
                title: 'Park Fibre',
                breadcrumb: 'Park Fibre',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'octotelfttb',
            name: 'octotelfttbservice',
            component: PppoeFttbService,
            meta: {
                title: 'Octotel FTTB',
                breadcrumb: 'Octotel FTTB',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'voconnectbusinesswireless',
            name: 'pppoe-wireless-service',
            component: PppoeFttbService,
            meta: {
                title: 'VO Connect Business Wireless',
                breadcrumb: 'Business Wireless',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'ftto',
            name: 'fttoservice',
            component: FttoService,
            meta: {
                title: 'Fibre to the Office',
                breadcrumb: 'FTTO',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'openserve',
            name: 'openserveservice',
            component: OpenserveService,
            meta: {
                title: 'Openserve',
                breadcrumb: 'Openserve',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'openservemplsvpnmulticonnectivity',
            name: 'openservemplsvpnmulticonnectivity',
            component: OpenserveMplsVpnMultiConnectivity,
            meta: {
                title: 'Openserve MPLS VPN Multi Connectivity',
                breadcrumb: 'Openserve',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'openserveserviceregrades',
            name: 'openserveserviceregrade',
            component: OpenserveServiceRegrades,
            meta: {
                title: 'Openserve Regrade',
                breadcrumb: 'Regrade',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'openservefttb',
            name: 'openservefttbservice',
            component: FttbService,
            meta: {
                title: 'Openserve FTTB',
                breadcrumb: 'Openserve FTTB',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'dfafttb',
            name: 'dfafttbservice',
            component: FttbService,
            meta: {
                title: 'DFA FTTB',
                breadcrumb: 'DFA FTTB',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'frogfootfttb',
            name: 'frogfootfttbservice',
            component: FttbService,
            meta: {
                title: 'Frogfoot FTTB',
                breadcrumb: 'Frogfoot FTTB',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'liqiudfttb',
            name: 'liquidfttbservice',
            component: FttbService,
            meta: {
                title: 'Liquid FTTB',
                breadcrumb: 'Liquid FTTB',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'adsline',
            name: 'adsllineservice',
            component: AdslLineService,
            meta: {
                title: 'Adsl Line',
                breadcrumb: 'Adsl Line',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'l2tpstaticip',
            name: 'l2tpstaticipservice',
            component: L2tpStaticIpService,
            meta: {
                title: 'L2tp Static IP',
                breadcrumb: 'L2tp Static IP',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'layer2link',
            name: 'layer2link',
            component: Layer2LinkService,
            meta: {
                title: 'Layer2 Link',
                breadcrumb: 'Layer2 Link',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'managedapn',
            name: 'managedapn',
            component: ManagedApnService,
            meta: {
                title: 'Managed APN',
                breadcrumb: 'Managed APN',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'privatemtnapn',
            name: 'privatemtnapn',
            component: PrivateMtnApnService,
            meta: {
                title: 'Private MTN APN',
                breadcrumb: 'Private MTN APN',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'internaldnsservice',
            name: 'internaldnsservice',
            component: InternalDnsService,
            meta: {
                title: 'Internal DNS',
                breadcrumb: 'Internal DNS',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'ncentral',
            name: 'ncentral',
            component: ManagedServicesNCentral,
            meta: {
                title: 'Managed Services NCentral',
                breadcrumb: 'Managed Services NCentral',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'domainservice',
            name: 'domainservice',
            component: DomainService,
            meta: {
                title: 'Domain Service',
                breadcrumb: 'Domain Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'domainhostingonlyservice',
            name: 'domainhostingonlyservice',
            component: DomainHostingOnlyService,
            meta: {
                title: 'Domain Hosting Only Service',
                breadcrumb: 'Domain Hosting Only Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'cpanelhosting',
            name: 'cpanelhosting',
            component: CPanelHosting,
            meta: {
                title: 'CPanel Service',
                breadcrumb: 'CPanel Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'axxesslte',
            name: 'axxesslteservice',
            component: AxxessLteService,
            meta: {
                title: 'Axxess LTE Service',
                breadcrumb: 'Axxess LTE Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'axxessftth',
            name: 'axxessftthservice',
            component: AxxessFtthService,
            meta: {
                title: 'Axxess FTTH Service',
                breadcrumb: 'Axxess FTTH Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'zimbramail',
            name: 'zimbramailservice',
            component: ZimbraMailService,
            meta: {
                title: 'Zimbra Mail Service',
                breadcrumb: 'Zimbra Mail Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'liquidsubscription',
            name: 'liquidsubscriptionservice',
            component: LiquidSubscriptionService,
            meta: {
                title: 'Liquid Subscription Service',
                breadcrumb: 'Liquid Subscription Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'mplsoveraxxesslte',
            name: 'mplsoveraxxesslte',
            component: AxxessLteService,
            meta: {
                title: 'MPLS Over Axxess LTE',
                breadcrumb: 'MPLS Over Axxess LTE',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'mplsvpn',
            name: 'mplsvpn',
            component: MplsVpn,
            meta: {
                title: 'MPLS VPN',
                breadcrumb: 'MPLS VPN',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'mplsoverinternet',
            name: 'mplsoverinternet',
            component: MplsOverInternet,
            meta: {
                title: 'MPLS Over Internet',
                breadcrumb: 'MPLS Over Internet',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'routerrental',
            name: 'routerrental',
            component: RouterRentalService,
            meta: {
                title: 'Router Rental',
                breadcrumb: 'Router Rental',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'fax2emailservice',
            name: 'fax2emailservice',
            component: FaxToEmailService,
            meta: {
                title: 'Fax to Email',
                breadcrumb: 'Fax to Email',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'equipmentrental',
            name: 'equipmentrental',
            component: EquipmentRentalService,
            meta: {
                title: 'Equipment Rental',
                breadcrumb: 'Equipment Rental',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'virtualserver',
            name: 'virtualserver',
            component: VirtualServerService,
            meta: {
                title: 'Virtual Server',
                breadcrumb: 'Virtual Server',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'splaservice',
            name: 'splaservice',
            component: SplaService,
            meta: {
                title: 'SPLA Service',
                breadcrumb: 'SPLA Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'ssdvirtualserver',
            name: 'ssdvirtualserver',
            component: VirtualServerSsdCloudService,
            meta: {
                title: 'SSD Cloud Virtual Server',
                breadcrumb: 'SSD Cloud Virtual Server',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'enterprisevirtualserver',
            name: 'enterprisevirtualserver',
            component: VirtualServerEnterpriseService,
            meta: {
                title: 'Enterprise Virtual Server',
                breadcrumb: 'Enterprise Virtual Server',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'awsservice',
            name: 'awsservice',
            component: AwsService,
            meta: {
                title: 'AWS',
                breadcrumb: 'AWS',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'ionlineapn',
            name: 'ionlineapn',
            component: IonlineApnService,
            meta: {
                title: 'iOnline APN',
                breadcrumb: 'iOnline APN',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'documentation',
            name: 'saledocumentation',
            component: SaleDocumentation,
            meta: {
                title: 'Documentation',
                breadcrumb: 'Documentation',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'admin',
            name: 'saleadmin',
            component: SaleAdminDetail,
            meta: {
                title: 'Admin',
                breadcrumb: 'Admin',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'billing',
            name: 'salebilling',
            component: SaleBillingDetail,
            meta: {
                title: 'Billing',
                breadcrumb: 'Billing',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'billinghistory',
            name: 'salebillinghistory',
            component: SaleBillingHistory,
            meta: {
                title: 'History ',
                breadcrumb: 'Billing History',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'productdetail',
            name: 'saleproduct',
            component: SaleProductDetails,
            meta: {
                title: 'Product',
                breadcrumb: 'Product',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'salehistory',
            name: 'salehistory',
            component: SaleHistory,
            meta: {
                title: 'Sale History',
                breadcrumb: 'Sale History',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'objhistory/:objKey/:objClass',
            name: 'objhistory',
            component: ObjHistory,
            props: true,
            meta: {
                title: 'Object History',
                breadcrumb: 'Object History',
                context: 'sale',
                permissions: ['STAFF']
            }
        },
        {
            path: 'cabinetrental',
            name: 'cabinetrentalservice',
            component: CabinetRentalService,
            meta: {
                title: 'Cabinet Rental',
                breadcrumb: 'Cabinet Rental',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'mimecastmail',
            name: 'mimecastmailservice',
            component: MimecastMailService,
            meta: {
                title: 'Mimecast Mail',
                breadcrumb: 'Mimecast Mail',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'cabinetpowerbilling',
            name: 'cabinetpowerservice',
            component: CabinetPowerBillingService,
            meta: {
                title: 'Cabinet Power Billing',
                breadcrumb: 'Cabinet Power Billing',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'leasedlinediginet',
            name: 'leasedlinediginetservice',
            component: DiginetService,
            meta: {
                title: 'Leased Line Diginet',
                breadcrumb: 'Leased Line Diginet',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'pointtopointdiginet',
            name: 'pointtopointdiginetservice',
            component: DiginetService,
            meta: {
                title: 'Point To Point Diginet',
                breadcrumb: 'Point To Point Diginet',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'metroconnectmpls',
            name: 'metroconnectmpls',
            component: MetroConnectMpls,
            meta: {
                title: 'Metro Connect MPLS Solution',
                breadcrumb: 'Metro Connect MPLS Solution',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'openservemplsoverfibre',
            name: 'openservemplsoverfibreservice',
            component: OpenserveMplsOverFibreService,
            meta: {
                title: 'Openserve MPLS Implementation',
                breadcrumb: 'Openserve MPLS Implementation',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'solarwindsbackup',
            name: 'solarwindsbackup',
            component: SolarwindsBackupService,
            meta: {
                title: 'Solarwinds Backup Service',
                breadcrumb: 'Solarwinds Backup Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        // {
        //     path: 'hostedlan',
        //     name: 'hostedlanservice',
        //     component: HostedLanService,
        //     meta: {
        //         title: 'Hosted Lan Service',
        //         breadcrumb: 'Hosted Lan Service',
        //         context: 'sale',
        //         permissions: ['*']
        //     }
        // },
        {
            path: 'HostedVlanService',
            name: 'HostedVlanService',
            component: HostedVlanService,
            meta: {
                title: 'Hosted VLAN Service',
                breadcrumb: 'Hosted VLAN Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'vumatelftth',
            name: 'activeethernetservice',
            component: ActiveEthernetService,
            meta: {
                title: 'Vumatel Active Ethernet',
                breadcrumb: 'Vumatel Active Ethernet',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'zimbrasharedmail',
            name: 'zimbrasharedmailservice',
            component: ZimbraSharedMailService,
            meta: {
                title: 'Zimbra Shared Mail Service',
                breadcrumb: 'Zimbra Shared Mail Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'zimbrashareddomainservice',
            name: 'zimbrashareddomainservice',
            component: ZimbraSharedDomain,
            meta: {
                title: 'Zimbra Shared Domain Service',
                breadcrumb: 'Zimbra Shared Domain Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'googleworkspace',
            name: 'googleworkspaceservice',
            component: GoogleWorkspaceService,
            meta: {
                title: 'Google Workspace Service',
                breadcrumb: 'Google Workspace Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'softwareservice',
            name: 'softwareservice',
            component: SoftwareService,
            meta: {
                title: 'Recurring Software Service',
                breadcrumb: 'Software Service',
                context: 'sale',
                permissions: ['*']

            }
        },
        {
            path: 'liquidlayer2',
            name: 'liquidlayer2',
            component: LiquidLayer2Service,
            meta: {
                title: 'Liquid Layer 2 Service',
                breadcrumb: 'Liquid Layer 2 Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'homevoice',
            name: 'homevoiceservice',
            component: HomeVoiceService,
            meta: {
                title: 'Home Voice Service',
                breadcrumb: 'Home Voice Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'wanatelpbxservice',
            name: 'wanatelpbxservice',
            component: WanatelPbxService,
            meta: {
                title: 'Wanatel PBX Service',
                breadcrumb: 'Wanatel PBX Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'bulkdidservice',
            name: 'bulkdidservice',
            component: BulkDidService,
            meta: {
                title: 'Bulk DID Service',
                breadcrumb: 'Bulk DID Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'internationaldidservice',
            name: 'internationaldidservice',
            component: InternationalDidService,
            meta: {
                title: 'International DID Service',
                breadcrumb: 'International DID Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'cx3pbxservice',
            name: 'cx3pbxservice',
            component: Cx3PbxService,
            meta: {
                title: '3CX PBX Service',
                breadcrumb: '3CX PBX Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'voiceminutes',
            name: 'voiceminutes',
            component: VoiceMinutesService,
            meta: {
                title: 'Voice Minutes Service',
                breadcrumb: 'Voice Minutes Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'crossconnect',
            name: 'crossconnectservice',
            component: CrossConnectService,
            meta: {
                title: 'Cross-Connect Service',
                breadcrumb: 'Cross-Connect Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'DataCentreInternet',
            name: 'DataCentreInternet',
            component: DataCentreInternetService,
            meta: {
                title: 'Data Centre Internet',
                breadcrumb: 'Data Centre Internet',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'LegacyDomainRegistrationService',
            name: 'LegacyDomainRegistrationService',
            component: LegacyDomainRegistrationService,
            meta: {
                title: 'Legacy Domain Registration',
                breadcrumb: 'Legacy Domain Registration',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'LegacySslCertificateService',
            name: 'LegacySslCertificateService',
            component: LegacySslCertificateService,
            meta: {
                title: 'Legacy SSL Certificate',
                breadcrumb: 'Legacy SSL Certificate',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'LegacyRemoteDomainService',
            name: 'LegacyRemoteDomainService',
            component: LegacyRemoteDomainService,
            meta: {
                title: 'Legacy Remote Domain',
                breadcrumb: 'Legacy Remote Domain',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'LegacyGenericService',
            name: 'LegacyGenericService',
            component: LegacyGenericService,
            meta: {
                title: 'Legacy Generic Service',
                breadcrumb: 'Legacy Generic Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'LegacyDeviceManagementService',
            name: 'LegacyDeviceManagementService',
            component: LegacyDeviceManagementService,
            meta: {
                title: 'Legacy Device Management',
                breadcrumb: 'Legacy Device Management',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'LegacyParkFibreService',
            name: 'LegacyParkFibreService',
            component: LegacyParkFibreService,
            meta: {
                title: 'Legacy Park Fibre',
                breadcrumb: 'Legacy Park Fibre',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'legacyvlanhostednetworkservice',
            name: 'legacyvlanhostednetworkservice',
            component: LegacyHostedVlanNetworkService,
            meta: {
                title: 'Legacy Hosted Vlan Network',
                breadcrumb: 'Legacy Hosted Vlan Network',
                context: 'sale',
                permissions: ['*'],
            },
        },
        {
            path: 'LegacyWebHostingService',
            name: 'LegacyWebHostingService',
            component: LegacyWebHostingService,
            meta: {
                title: 'Legacy Web Hosting',
                breadcrumb: 'Legacy Web Hosting',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'legacyserverhostingservice',
            name: 'legacyserverhostingservice',
            component: LegacyServerHostingService,
            meta: {
                title: 'Legacy Server Hosting Service',
                breadcrumb: 'Legacy Server Hosting Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'legacyserverhostingfirewallservice',
            name: 'legacyserverhostingfirewallservice',
            component: LegacyServerHostingFirewallService,
            meta: {
                title: 'Legacy Server Hosting Firewall',
                breadcrumb: 'Legacy Server Hosting Firewall',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'legacyauthsmtpservice',
            name: 'legacyauthsmtpservice',
            component: LegacyAuthSmtpService,
            meta: {
                title: 'Legacy Auth SMTP',
                breadcrumb: 'Legacy Auth SMTP',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'layer3service',
            name: 'layer3service',
            component: Layer3Service,
            meta: {
                title: 'Layer 3 Service',
                breadcrumb: 'Layer 3 Service',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'office365backup',
            name: 'office365backup',
            component: Office365BackupService,
            meta: {
                title: 'Office 365 Backup',
                breadcrumb: 'Office 365 Backup',
                context: 'sale',
                permissions: ['*']
            }
        },
 {
            path: 'sslcertificate',
            name: 'sslcertificate',
            component: SslCertificateService,
            meta: {
                title: 'SSL Certificate',
                breadcrumb: 'SSL Certificate Service',
                                context: 'sale',
                                permissions: ['*']
            }
        },
       {
            path: 'office365',
            name: 'office365',
            component: Office365Service,
            meta: {
                title: 'Office 365',
                breadcrumb: 'Office 365',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'cyberriskawareservice',
            name: 'cyberriskawareservice',
            component: CyberRiskAwareService,
            meta: {
                title: 'CyberRisk Aware',
                breadcrumb: 'CyberRisk Aware',
                context: 'sale',
                permissions: ['*']
            }
        },
        {
            path: 'authsmtpservice',
            name: 'authsmtpservice',
            component: AuthSmtpService,
            meta: {
                title: 'Auth SMTP Service',
                breadcrumb: 'Auth SMTP Service',
                context: 'sale',
                permissions: ['*']

            }
        },
          {
            path: 'synaqservice',
            name: 'synaqservice',
            component: SynaqService,
            meta: {
                title: 'Synaq',
                breadcrumb: 'Synaq',
                context: 'sale',
                permissions: ['*']
            }
          },
          {
            path: 'websitestarterservice',
            name: 'websitestarterservice',
            component: WebsiteStarterService,
            meta: {
                title: 'Web Hosting',
                breadcrumb: 'Web Hosting',
                context: 'sale',
                permissions: ['*']
            }
          },
          {
            path: 'websitephpservice',
            name: 'websitephpservice',
            component: WebsitePhpService,
            meta: {
                title: 'Web Hosting',
                breadcrumb: 'Web Hosting',
                context: 'sale',
                permissions: ['*']
            }
          },
          {
            path: 'webhostingregrade',
            name: 'webhostingregrade',
            component: WebHostingRegrade,
            meta: {
                title: 'Web Hosting Regrade',
                breadcrumb: 'Regrade',
                permissions: ['STAFF']
            },
        },
        ]
    },
    {
        path: 'documents',
        name: 'clientdocuments',
        component: ClientDocuments,
        meta: {
            title: 'Invoices & Credit Notes',
            breadcrumb: 'Invoices & Credit Notes',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'documents',
        name: 'clientbillingdocuments',
        component: ClientDocuments,
        meta: {
            title: 'Invoices & Credit Notes',
            breadcrumb: 'Invoices & Credit Notes',
            context: 'client',
            permissions: ['CLIENT_ADMIN', 'BILLING']
        }
    },
    {
        path: 'documentation',
        name: 'clientdocumentation',
        component: ClientDocumentation,
        meta: {
            title: 'Documentation',
            breadcrumb: 'Documentation',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'statement',
        name: 'clientstatement',
        component: ClientStatement,
        meta: {
            title: 'Statement',
            breadcrumb: 'Statement',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'statement',
        name: 'clientbillingstatement',
        component: ClientStatement,
        meta: {
            title: 'Statement',
            breadcrumb: 'Statement',
            context: 'client',
            permissions: ['CLIENT_ADMIN', 'BILLING']
        }
    },
    {
        path: 'document/:documentNumber',
        name: 'documentedit',
        component: DocumentEdit,
        meta: {
            title: 'Invoices / Credit Notes',
            breadcrumb: 'View Document',
            context: 'client',
            permissions: ['STAFF', 'CLIENT_ADMIN', 'BILLING']
        }
    },
    {
        path: 'profile',
        name: 'clientdetail',
        component: ClientProfile,
        meta: {
            title: 'Client',
            breadcrumb: 'Client',
            context: 'client',
            permissions: ['*']
        }
    },
    {
        path: 'users',
        name: 'clientusers',
        component: ClientPersons,
        meta: {
            title: 'Client Users',
            breadcrumb: 'Client Users',
            context: 'client',
            permissions: ['STAFF', 'CLIENT']
        },
        props: true
    },
    {
        path: 'history',
        name: 'clienthistory',
        component: ClientHistory,
        meta: {
            title: 'Client History',
            breadcrumb: 'Client History',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'resellerclients',
        name: 'resellerclients',
        component: ResellerClients,
        meta: {
            title: 'Reseller Clients',
            breadcrumb: 'Reseller Clients',
            context: 'client',
            permissions: ['STAFF', 'RESELLER']
        }
    },
    {
        path: 'resellercommission',
        name: 'resellercommission',
        component: ResellerCommission,
        meta: {
            title: 'Reseller Commission',
            breadcrumb: 'Reseller Clients',
            context: 'reseller',
            permissions: ['STAFF']
        }
    },
    {
        path: 'clientsettlements',
        name: 'clientsettlements',
        component: ClientSettlements,
        meta: {
            title: 'Client Settlements',
            breadcrumb: 'Client Settlements',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'clientsettleaccounts',
        name: 'clientsettleaccounts',
        component: ClientSettleAccount,
        meta: {
            title: 'Settle Account',
            breadcrumb: 'Settle Account',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'clientreversals/:settlementNumber',
        name: 'clientreversals',
        component: ClientReversals,
        meta: {
            title: 'Client Reversals',
            breadcrumb: 'Client Reversals',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'workorders',
        name: 'workorders',
        component: ClientWorkOrders,
        meta: {
            title: 'Quotes and Work Orders',
            breadcrumb: 'Quotes and Work Orders',
            context: 'client',
            permissions: ['STAFF']
        }
    },
    {
        path: 'clientworkorders',
        name: 'clientworkorders',
        component: ClientWorkOrders,
        meta: {
            title: 'Work Orders',
            breadcrumb: 'Work Orders',
            context: 'client',
            permissions: ['CLIENT_ADMIN', 'WORK_ORDERS']
        }
    },
    {
        path: 'workorderdetails/:workOrderNumber',
        name: 'workorderdetails',
        component: WorkOrderDetails,
        meta: {
            title: 'Work Order Details',
            breadcrumb: 'Work Order Details',
            context: 'client',
            permissions: ['STAFF', 'CLIENT_ADMIN', 'WORK_ORDERS']
        }
    },
    {
        path: 'helpdesktickets',
        name: 'helpdesktickets',
        component: ClientHelpdeskTickets,
        meta: {
            title: 'Client Helpdesk Tickets',
            breadcrumb: 'Client Helpdesk Tickets',
            context: 'client',
            permissions: ['STAFF', 'CLIENT']
        }
    },
    ]
}
];
