<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row row-spacing">
                    <div class="col-md-4">
                        <label>Search Tickets</label>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search by Ticket Number, Description, User or Agent" v-model="searchTerm">
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Filter by status</label>
                        <div class="form-group">
                            <v-select :clearable="true" v-model="status" :options="statuses" label="name" valueProp="name">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="mr-1">Filter by date</label>
                        <small>(90 days by default)</small>
                        <div class="form-group">
                            <div class="input-group">
                                <DateInput 
                                    type="date" 
                                    format="YYYY-MM-DD"
                                    :placeholder="'Select starting date range'"
                                    style="width: 100%"
                                    v-model="createdDate"
                                    @input="$emit('getAllTickets', createdDate)"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table table-hover table-responsive table-bordered">
            <thead>
                <tr class="dark-row">
                    <th @click="sortBy('ticketNumber')" class="sort-col th-width">
                        Ticket Number
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ticketNumber' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ticketNumber' && !reverse"></i>
                    </th>
                    <th @click="sortBy('description')" class="sort-col">
                        Description
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
                    </th>
                    <th  @click="sortBy('user')" class="sort-col th-width">
                        User
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'user' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'user' && !reverse"></i>
                    </th>
                    <th  @click="sortBy('agent')" class="sort-col th-width">
                        Agent
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'agent' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'agent' && !reverse"></i>
                    </th>
                    <th @click="sortBy('status')" class="sort-col th-width">
                        Status
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'status' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'status' && !reverse"></i>
                    </th>
                    <th  @click="sortBy('created')" class="sort-col th-width">
                        Created
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'created' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'created' && !reverse"></i>
                    </th>
                </tr>
            </thead>
            <tbody v-if="filteredTickets.length > 0">
                <tr v-for="ticket in filteredTickets" :key="ticket.ticketNumber" @click="goToHaloTickets(ticket)" v-tooltip="'View ticket in Halo Portal'">
                    <td class="td-pointer">{{ ticket.ticketNumber }}</td>
                    <td class="td-pointer">{{ ticket.description }}</td>
                    <td class="td-pointer">{{ ticket.user }}</td>
                    <td class="td-pointer">{{ ticket.agent }}</td>
                    <td class="td-pointer"><span :class="getStatusBadge(ticket.status)">{{ticket.status}}</span></td>
                    <td class="text-nowrap td-pointer">{{dateTimeFormat(ticket.created)}}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="6">No Helpdesk Tickets to display</td>
                </tr>
            </tbody>
        </table>
        <CreateHelpdeskTicket 
            ref="openCreateNewTicketModal"
            :modalTitle="modalTitle"
            @updateTicketList="updateTicketList"
        />
    </div>
</template>

<script>
import CreateHelpdeskTicket from '@/components/Admin/Modals/CreateHelpdeskTicket'; 
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
    components: {
        CreateHelpdeskTicket,
        DateInput
    },
    props: {
        ticketList: {
            type: Array,
            required: true
        },
        statuses: {
            type: Array,
            required: true
        },
        createdDate: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            modalTitle: '',
            searchTerm: '',
            filteredTicketList: [],
            status: '',
            sortKey: 'ticketNumber',
            reverse: false,
        }
    },
    computed: {
        filteredTickets() {
            this.filteredTicketList = this.ticketList;
            
            if (this.searchTerm) {                    
                this.filteredTicketList = this.filteredTicketList.filter((ticket) =>                       
                    ticket.ticketNumber.toString().includes(this.searchTerm) ||
                    ticket.description.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    ticket.user.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    ticket.agent.toLowerCase().includes(this.searchTerm.toLowerCase()) 
                );                
            };

            if (this.status) {
                this.filteredTicketList = this.filteredTicketList.filter((ticket) => ticket.status === this.status); 
                this.setDefaultDate();
            }

            if (this.reverse) {
                return _.orderBy(this.filteredTicketList, this.sortKey, 'asc');
            } else {
                return _.orderBy(this.filteredTicketList, this.sortKey, 'desc');
            }
        }
    },
    methods: {
        getStatusBadge: function(status) {
            let badge = 'badge';

            if (status == 'New'){
                badge = 'badge bg-info';
            }

            if (status == 'In Progress'){
                badge = 'badge bg-gray-darker';
            }

            if (status == 'Completed') {
                badge = 'badge bg-success'
            }

            if (status == 'Closed') {
                badge = 'badge bg-red text-white'
            }

            return badge;
        },
        updateTicketList(data) {
            this.ticketList.push(data);
        },
        sortBy(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
            this.sortKey = sortKey;
        },
        setDefaultDate: function() {             
            if (this.filteredTicketList.length > 0) {
                this.createdDate = this.dateTimeFormat(this.filteredTicketList.at(-1).created);       
            }
        },
        goToHaloTickets: function(ticket) {            
            window.open(ticket.url);
        }
    }
}
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        width: calc(100px + 100em);
    }

    .th-width {
        width: calc(100px + 30em);
    }

    .th-options {
        width: 4%;
    }

}
.th-options, .td-options {
    cursor: pointer !important;
        text-align: center;
        
}

.sort-col {
    cursor: pointer;
}

.td-pointer {
    cursor: pointer !important;
}

.table-hover td {
    cursor: default;
}

.td-button:hover {
    color: red;
}

.td-options .dropdown-menu .dropdown-item:hover {
    color: red;
}

</style>